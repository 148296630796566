<template>
  <div class="mainContainer container" style="">
    <div id="logoDiv" class=""><img src="images/logo_w_text_250.png"></div>
    <h5 style="margin:auto;text-align: center;">Verify Email</h5>
    <hr>

    <div class="alert alert-info">A verification code has been emailed to you. Please check your spam folder if you didn't receive it. Or use <a href="/passwordreset">Password Reset</a> to get a new code.</div>


    <div class="row" v-if="pageStatus !== 'resetSuccess'">
      <div class="col-md-12">

        <div class="card-container">
          <form @submit.prevent="handlePasswordReset">
              <div class="form-group">
                <label for="email">Email</label>
                <input name="email" type="email" class="form-control" v-model="email" />
                <ul class="error-feedback" v-if="emailErrors.length > 0" v-html="emailErrors"></ul>
              </div>

            <div class="form-group">
              <label for="resetCode">Your Verification Code:</label>
              <input name="resetCode" type="text" class="form-control" v-model="resetCode"/>
              <ul class="error-feedback" v-if="resetCodeErrors.length > 0" v-html="resetCodeErrors"></ul>
            </div>



              <div class="form-group" >
                <button class="btn btn-primary btn-block" :disabled="loading"  v-if="this.pageStatus === 'validateResetCode'">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Verify Email</span>
                </button>

              </div>


          </form>

          <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'" v-html="message"
          >
          </div>
        </div>
      </div>
    </div>

    <div class="" v-if="pageStatus === 'verificationSuccess'">
      <div class="alert alert-success msg continue">
        Your email has been verified. Continue to <br><button class="btn btn-primary" @click="this.$root.gotoRoute({ path: '/login' })">Log In</button>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: "VerifyEmail",
  components: {

  },
  data() {
    return {
      successful: false,
      loading: false,
      message: "",
      pageStatus:'validateResetCode',
      email:null,
      resetCode:null,
      resetCodeValid: true,
      password:null,
      passwordConfirmation:null,
      passwordValidationErrors:'',

      emailErrors:'',
      resetCodeErrors:'',
      passwordErrors:'',
      passwordConfirmationErrors:'',
      hasValidationError: false,

      formData:{},

    };
  },
  computed: {


  },

  created() {
    this.email = this.$route.query.email;
  },


  methods: {
    handlePasswordReset() {


      this.hasValidationError = false;
      this.validateEmail();
      this.validateResetCode();
      if(this.hasValidationError) return;


      this.message = "";
      this.successful = false;
      this.loading = true;



      if(this.pageStatus === 'validateResetCode'){
        this.axios
            .post(this.$hostApi + 'resetpass/',{task:'verifyEmail', email:this.email, resetCode:this.resetCode})
            .then(response =>{
              //console.log(response);
              if(response.data === 'ok'){
                this.pageStatus = 'verificationSuccess';
                this.successful = false;
                this.loading = false;
              }else{
                this.resetCodeValid = false;
                this.successful = false;
                this.loading = false;
                this.message = 'Invalid verification code! Please check for any typos or try <a href="/passwordreset">Reset Password</a>.'
                //console.log(this.message);
              }
            })
            .catch(error => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
              this.message =
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();
              //this.message = JSON.stringify(error) || error;
              this.successful = false;
              this.loading = false;
            })


      }


    },

    showRegForm(){

    },

    validateEmail() {
      //console.log('email is '+this.email);
      let errors = (/\S+@\S+\.\S+/.test(this.email)) ? '' : '<li>email is not valid</li>';
      errors += this.$root.validateLength(this.email,0,100);
      this.emailErrors = errors;
      if(this.emailErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateResetCode(){
      this.resetCodeErrors = this.$root.validateLength(this.resetCode,0,10);
      if(this.resetCodeErrors.length > 0){
        this.hasValidationError = true;
      }
    },



    validateAll(){
      this.validateResetCode();
      this.validateEmail();

    }
  },

  mounted() {
    document.title = "Verify Email";
  },
};
</script>

<style scoped>
#logoDiv{
  margin:0 auto 20px auto;
  padding:10px 20px;
  text-align: center;
}

#logoDiv img{
  width:200px;
}


.error-feedback{
  font-size:10px;
  color:red;

}

.error-feedback-div{
  margin-bottom:5px;
}

.form-group{
  margin:20px;
}

.continue{
  text-align: center;
}


</style>